// @ts-nocheck
import { useAPIFetch } from '~/composables/use-api-fetch'

const nuxtApp = useNuxtApp()
const route = useRouter().currentRoute.value
const router = useRouter()
const localeRoute = useLocaleRoute()
export const useCenter = definePiniaStore('centers', {
  state: () => ({
    center: null,
    loading: false,
  }),
  actions: {
    async getCenter() {
      this.loading = true
      const { data, refresh }: any = await useAPIFetch(
        `umbraco/delivery/api/v1/content/item/${<string>route.params.slug[0]}`,
        {
          server: true,
          method: 'GET',
          query: {
            expand: 'all',
          },
        }
      )
      if (data.value) {
        this.center = data.value
        useSeoMeta({
          title: data.value?.name,
          description: data.value?.properties?.description,
        })
      }
      this.loading = false
    },
    clearCenter() {
      this.center = null
    },
  },
})
